<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="content">
			<el-carousel :interval="interval" arrow="always" indicator-position="outside">
				<el-carousel-item v-for="(item, index) in items" :key="index">
					<img :src="item.image" alt="" style="width: 100%;height: 700px;">
				</el-carousel-item>
			</el-carousel>
			<div class="service">
				<div class="title">
					<div class="mainTitle">SOLUTION</div>
					<div class="subTitleText">我们提供定制化设计解决方案</div>
				</div>
				<div class="simpleText">
					<p>品牌营销、活动策划、视觉设计、软件开发领域从业超过6年！我们策划执行全国大中小型活动2000+</p>
					<p>我们倡导让客户感知-视觉体验与活动营销的价值，而不仅仅是简单的参与</p>
				</div>
				<div class="tab_pane_list box leftAndRight">
					<ul class="tab_pane_item" v-for="(item, index) in paneList" :key="index">
						<li>
							<img :src="item.image" />
							<div>{{item.title}}</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="service">
				<div class="title">
					<div class="mainTitle">ACTIVITY</div>
					<div class="subTitleText">热门活动</div>
				</div>
				<div class="simpleText">
					<p>活动消息早知道，我们只想让每个活动都精彩绝伦和与众不同</p>
				</div>

			</div>
			<div class="activity box">
				<div class="a_list leftAndRight">
					<div class="a_item" v-for="(item, index) in activityList" :key="index" @click="del(item.id)">
						<img :src="item.image" />
						<div style="padding: 15px;">
							<div class="a_title">
								{{item.title}}
							</div>
							<div class="a_dsc">
								{{item.des}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<foot></foot>
			<!-- <router-view></router-view> -->
		</div>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	export default {
		name: 'App',
		components: {
			NavigationBar,
			foot

		},
		data() {
			return {
				interval: 3000,
				items: [{
					image: '../../../public/img/lb1.png'
				}, {
					image: '../../../public/img/lb2.png'
				}],
				date: {},
				product: {},
				news: [],
				paneList: [{
						image: require('../../../public/img/fan1.jpg'),
						title: '舞台新闻发布会'
					},
					{
						image: require('../../../public/img/fan2.jpg'),
						title: '舞台新闻发布会'
					},
					{
						image: require('../../../public/img/fan3.jpg'),
						title: '舞台新闻发布会'
					},
				],
				activityList: [{
					image: require('../../../public/img/pen1.jpg'),
					title: '中国主题公园行业现状分析及发展趋势预测发布会',
					des: '国外用50 年时间开发了30 个主题公园，而国内用短短20 年时间就开发了2500 个主题公园，基本覆盖了我国大部...'
				},
				{
					image: require('../../../public/img/pen1.jpg'),
					title: '中国主题公园行业现状分析及发展趋势预测发布会',
					des: '国外用50 年时间开发了30 个主题公园，而国内用短短20 年时间就开发了2500 个主题公园，基本覆盖了我国大部...'
				},
				{
					image: require('../../../public/img/pen1.jpg'),
					title: '中国主题公园行业现状分析及发展趋势预测发布会',
					des: '国外用50 年时间开发了30 个主题公园，而国内用短短20 年时间就开发了2500 个主题公园，基本覆盖了我国大部...'
				}]
			}
		},

		created() {
			this.getData()
			this.getList()
		},
		methods: {
			getData() {
				let _this = this;
				let urls =
					"api/index/index"
				_this.post(urls, res => {
					_this.items = res.data.slide;

				})
			},
			del(id){
				this.$router.push({ name: 'xiwen', query: { id: id }});
			},
			getList() {
				let _this = this;
				let urls =
					"api/article/index?category_id=3&page=1&pagesize=3"
				_this.post(urls, res => {
					_this.activityList = res.data.list;
			
				})
			},
		}
	}
</script>

<style scoped lang="less">
	/deep/.el-carousel__container {
		height: 700px;
	}
	.title {
		.mainTitle {
			margin-bottom: 15px;
			font-size: 16px;
			padding-top: 10px;
			font-weight: normal;
			font-family: 微软雅黑;
			color: rgba(153, 153, 153, 1);
		}

		.subTitleText {
			font-size: 30px;
			line-height: 30px;
			font-weight: bold;
			font-family: 微软雅黑;
			color: rgba(196, 0, 0, 1);
		}
	}

	.simpleText {
		margin-top: 50px;
		color: #666666;
		clear: both;
		font-family: 微软雅黑;
		font-size: 20px;

		p {
			margin-bottom: 22px;
		}
	}

	.service {
		margin-top: 75px;



		.tab_pane_list {
			margin-top: 10px;


			.tab_pane_item {


				image {
					width: 330px;
					height: 230px;
				}

				li {
					overflow: hidden;
					white-space: nowrap;
				}

				li div {
					margin: 10px auto;
					font-size: 14px;
					color: #666;
				}
			}
		}
	}

	.activity {
		margin-bottom: 30px;
		.a_list {
			flex-wrap:wrap;
			.a_item {
				background: #fff;
				border-radius: 6px;
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
				overflow: hidden;
				min-width: 200px;
				width: 33%;
				vertical-align: top;
				height: 289px;

				img {
					height: 170px;
					width: 100%;
				}

				.a_title {
					vertical-align: middle;
					color: #333;
					font-size: 16px;
					text-align: left;
				}

				.a_dsc {
					color: #666;
					font-size: 14px;
					line-height: 26px;
					margin-top: 14px;
					white-space: normal;
					text-align: left;
				}
			}
		}
	}
</style>