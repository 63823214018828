<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="banner">
			<div class="box">
				<div class="banner_name">公益之路</div>
			</div>
		</div>
		<breadNav :tabList='tabList' @tabClick='tabClick'></breadNav>
		<div class="about_con box">
			<img src="../../../public/img/gen.jpg"/>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	import breadNav from '@/components/breadNav.vue'
	export default {
		components: {
			NavigationBar,
			foot,
			breadNav

		},
		data() {
			return {
				tabList: []
			}
		},
		methods: {
			tabClick(data) {
				console.log(data)
			},
		}
	}
</script>

<style scoped>
	.banner {
		background: url('../../../public/img/top5.png');
		width: 100%;
		height: 340px;
	}

	.banner_name {
		padding-top: 150px;
		font-size: 44px;
		color: #474747;
		text-align: left;
	}

	.about_con {
		margin: 30px auto;
		text-align: left;
	}

	.about_con img {
		width: 100%;
	}

	.about_con p {
		font-size: 15px;
		line-height: 1.6;
		color: #525252;
		margin-bottom: 25px;
	}
</style>