<template>
	<div class="n_nav">
		<div class="box">
			<ul class="divColumnCenter">
				<li :class="[{'active':index==currentIndex}]" v-for="(item, index) in tabList" :key="index"
					@click="tabClick(index,item.id)"><a>{{item.name}}</a></li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				currentIndex: -1,
			}
		},
		props: {
			tabList: {
				type: Array,
				required: true
			}
		},
		methods: {
			tabClick(index,id) {
				this.currentIndex = index;
				this.$emit('tabClick', id);
			}
		}
	};
</script>

<style>
	.n_nav {
		height: 75px;
		background-color: #ffffff;
		border-bottom: 1px solid #E5E5E5;
	}

	.n_nav li {
		float: left;
		margin-right: 30px;
	}

	.n_nav li a {
		font-size: 16px;
		display: block;
		color: #666666;
		padding: 0 6px;
		line-height: 72px;
		border-bottom: 3px solid rgba(255, 255, 255, 0);
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
		border-bottom: 3px solid #fff;
	}

	.n_nav li a:hover {
		color: #c9151e;
		border-bottom: 3px solid #c9151e;
	}
	.n_nav .active a {
		color: #c9151e;
		border-bottom: 3px solid #c9151e;
	}
</style>