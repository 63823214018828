<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="banner">
			<!-- <div class="box">
				<div class="banner_name">关于美盛</div>
			</div> -->
		</div>
		<!-- <breadNav :tabList='tabList' @tabClick='tabClick'></breadNav> -->
		<ul class="middle box leftAndRight">
			<li>
				<img src="../../../public/img/mid1.png"><br />
				<span>专业团队</span>
			</li>
			<li>
				<img src="../../../public/img/mid2.png"><br />
				<span>专业流程</span>
			</li>
			<li>
				<img src="../../../public/img/mid3.png"><br />
				<span>专业效率</span>
			</li>
			<li>
				<img src="../../../public/img/mid4.png"><br />
				<span>专业服务</span>
			</li>
		</ul>
		<div class="major">
			<div class="title">
				<div class="mainTitle">SERVICE</div>
				<div class="subTitleText">专业的服务</div>
			</div>
			<div class="simpleText">
				<p>通过研究商业模式来定义品牌模式提供优秀的品牌整合解决方案，令品牌长远发展，更具竞争力新成立企业，需要有好的标志，</p>
				<p>好的品牌形象有助于提升企业整体竞争力，公司专注于品牌规划，将为你的企业品牌进行整体的规划与设计，努力为您打造坚实品牌基础。</p>
			</div>
			<ul class="major_list box leftAndRight">
				<li>
					<div class="simple">
						我们的环球视野<br/>
						助您的<br/>
						品牌掌握未来
					</div>
					<div class="line">
						
					</div>
					<p class="p_con">
						竞争日新月异，传统品牌思维已过时，打破传统公司的模式化禁锢，以立足未来。<br/>
						谋划当下的新派品牌智略，量身定制品牌战略，助您的品牌避开弯路，直达目的地。
					</p>
				</li>
				<li>
					<div class="simple">
						我们的精准策略<br/>
						助您的<br/>
						产品业绩倍增
					</div>
					<div class="line">
						
					</div>
					<p class="p_con">
						失败的原车可能各不相同，但成功必有一个相同点，即策略精准。<br/>
						我们以品牌的核心视觉融合到营销传播的创意管理，推动视觉资产的增值，从而找到品牌机会和市场突点。
					</p>
				</li>
				<li>
					<div class="simple">
						我们的创新设计<br/>
						助您的<br/>
						企业独占市场
					</div>
					<div class="line">
						
					</div>
					<p class="p_con">
						您是否有此困惑？您合作的外脑，懂营销的公司不懂审美懂审美的公司又不懂营销 <br/>
						我们将营销与美学融会贯通，以出众的商业逻辑助您既赢得市场也赢得美誉。
					</p>
				</li>
			</ul>
			<div class="box b_con leftAndRight">
				<div class="b_left">
					<div class="title">
						<div class="mainTitle">Media agent</div>
						<div class="subTitleText">媒介代理</div>
					</div>
					<div class="simpleText">
						<p>地下车库灯箱广告网涵盖沃尔玛、友谊阿波罗、通程国际大酒店、</p>
						<p>华天大酒店等知名超市卖场、酒店及写字楼。</p>
					</div>
				</div>
				<img class="b_right" src="../../../public/img/b1.jpg">
			</div>
			<div class="box b_con leftAndRight">
				<img class="b_right" src="../../../public/img/b2.jpg">
				<div class="b_left" style="padding-left: 5%;width: 45%;">
					<div class="title">
						<div class="mainTitle">VI design and production</div>
						<div class="subTitleText">VI设计及制作</div>
					</div>
					<div class="simpleText">
						<p>企业各项VI设计，包含企业LOGO设计、</p>
						<p>企业画册、宣传海报等各项VI标识制作、各种招牌灯箱、门楣标识等</p>
					</div>
				</div>
				
			</div>
			<div class="box b_con leftAndRight">
				<div class="b_left">
					<div class="title">
						<div class="mainTitle">Activity planning and execution</div>
						<div class="subTitleText">活动策划及执行</div>
					</div>
					<div class="simpleText">
						<p>策划富有创意及影响力的公关事件及活动：</p>
						<p>主题论坛、新闻发布会、巡展及研讨会各类签约活动、</p>
						<p>大型展览展示、新品发布、产品促销扩赞助活动剪彩、捐赠</p>
						<p>等仪式及各类齐庆典活动。</p>
					</div>
				</div>
				<img class="b_right" src="../../../public/img/b3.jpg">
			</div>
		</div>
		<div class="work" >
			<div class="title">
				<div class="mainTitle">CUSTOMER SERVICE</div>
				<div class="subTitleText">服务客户</div>
			</div>
			<div class="simpleText">
				<p>我们与客户一起为市场留下了良好的口碑和深远的品牌价值</p>
				
			</div>
			<div class="p_box box leftAndRight">
				<img  src="../../../public/img/p1.jpg"/>
				<img  src="../../../public/img/p2.jpg"/>
				<img  src="../../../public/img/p3.jpg"/>
				<img  src="../../../public/img/p4.jpg"/>
			</div>
		</div>
		<foot></foot>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	// import breadNav from '@/components/breadNav.vue'
	export default {
		components: {
			NavigationBar,
			foot,
			// breadNav

		},
		data() {
			return {
				tabList: ['企业简介', '组织架构', '企业文化', '资质荣誉'],
				id: '',
				content: '',
				dates: {}
			}
		},
		mounted() {
			console.log(this.$route)
			this.id = this.$route.query.id;
			this.getData()
		},
		methods: {
			tabClick(id) {
				this.id = id;
				this.getData()
			},
			getData() {
				let _this = this;
				let urls =
					"api/article/detail?id=" + _this.id
				_this.post(urls, res => {
					console.log(res.data)
					_this.dates = res.data.info;
					_this.content = res.data.info.content;
					_this.tabList = res.data.art_list
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.work {
		margin-top: 30px;
	}
	.b_con {
		margin-bottom: 20px;
		text-align: left;
		.b_left,.b_right {
			width: 50%;
		}
	}
	.p_box {
		img {
			width: 230px;
			height: 138px;
		}
	}
	.major_list {
		margin: 50px auto;
		li {
			background: url('../../../public/img/mio.jpg');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			width: 353px;
			height: 347px;
			text-align: left;
			padding: 30px 20px;
			.simple {
				line-height: 38px;
				font-size: 24px;
				color: #333;
			}
			.line {
				background-color: rgb(196, 0, 0);
				width: 43px;
				height: 3px;
				margin: 30px 0;
			}
			.p_con {
				line-height: 28px;
				font-size: 16px;
			}
		}
	}

	.banner {
		background: url('../../../public/img/img7.jpg');
		width: 100%;
		height: 450px;
	}

	.banner_name {
		padding-top: 150px;
		font-size: 44px;
		color: #474747;
		text-align: left;
	}


	.middle {
		margin-top: 65px;
		margin-bottom: 30px;
	}

	.middle li img {
		width: 150px;
		height: 60px;
	}

	.middle li span {
		color: rgba(102, 102, 102, 1);
		font-size: 16px;
		margin-bottom: 14px;
		margin-top: 10px;
	}

	.title {
		
		.mainTitle {
			margin-bottom: 15px;
			font-size: 16px;
			padding-top: 10px;
			font-weight: normal;
			font-family: 微软雅黑;
			color: rgba(153, 153, 153, 1);
		}

		.subTitleText {
			font-size: 30px;
			line-height: 30px;
			font-weight: bold;
			font-family: 微软雅黑;
			color: rgba(196, 0, 0, 1);
		}
	}

	.simpleText {
		margin-top: 50px;
		color: #666666;
		clear: both;
		font-family: 微软雅黑;
		font-size: 20px;

		p {
			margin-bottom: 22px;
		}
	}
</style>