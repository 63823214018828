<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="content">
			<el-carousel :interval="interval" arrow="always" indicator-position="outside">
				<el-carousel-item v-for="(item, index) in items" :key="index">
					<img v-if="item.image" :src="item.image" alt="" style="width: 100%;height: 900px;">
				</el-carousel-item>
			</el-carousel>
			<div class="service leftAndRight box">
				<div class="left" style="width: 30%;">
					<div class="title">
						<div class="mainTitle">OUR SERVICE</div>
						<div class="subTitleText">业务项目</div>
					</div>
					<div class="simpleText">
						<p>专致于移动互联网+、智能硬件技术开发</p>
						<p>赋能企业数字化转型及升级</p>
					</div>
				</div>

				<div class="tab_pane_list leftAndRight" style="width: 70%">
					<ul class="tab_pane_item" v-for="(item, index) in ywList" :key="index">
						<li>
							<img :src="item.image" />
						</li>
					</ul>
				</div>
			</div>
			<div class="elemZoneBg leftAndRight box">
				<div class="item">
					<div class="elemTitle">APP开发</div>
					<p>【商城购物】【教育培训】</p>
					<p>【在线医疗】【商业地产 】</p>
				</div>
				<div class="item">
					<div class="elemTitle">会议策划</div>
					<p>六年行业经验</p>
					<p>多名六年经验+技术工程师</p>
				</div>
				<div class="item">
					<div class="elemTitle">众创空间</div>
					<p>1200场+落地活动均</p>
					<p>六年经验+执行团队</p>
				</div>
				<div class="item">
					<div class="elemTitle">会议白板</div>
					<p>多媒体触摸平板、智能会议</p>
					<p>白板、触摸一体机</p>
				</div>
			</div>
			<div class="company box">
				<div class="title">
					<div class="mainTitle">Company profile</div>
					<div class="subTitleText">公司简介</div>
				</div>
				<div class="simpleText">
					<p>湖南三好文化传媒有限公司成立于2014年
						凭借在前期创意、后期制作、技术研发等方面丰富经验的累积，先后创作出诸多成功的广告营销案例，期间也为客户提供整体运营管理解决方案及专业咨询服务，实现企业搭建与宣传一体化业务咨询与实践。
						同时通过与金融平台合作为客户提供专业的金融机具咨询业务，运用市场化的融资手段与多元化的创新金融工具，为客户提供专业、系统、稳健的金融服务解决方案，并谋求诚信、和谐、多赢的合作关系。
						我司秉承存好心、说好话、行好事的企业文化为客户提供量身定制、360°贴心服务、实战落地的优质服务。</p>
				</div>
				<div class="companyList leftAndRight ">
					<div class="companyItem">
						<div class="c_title">专业价值高的<br />服务公司</div>
						<img src="../../../public/img/c1.jpg" />
						<div class="c_des">我们是集策划、设计及执行一站式的服务公司，经过11年的风雨，凭借出色的策划设计及执行能力，如今已发展成为公关活动领域的导向标和践行者。</div>
					</div>
					<div class="companyItem">
						<div class="c_title">专业从事各类<br/>公关活动</div>
						<img src="../../../public/img/c2.jpg" />
						<div class="c_des">地产推广、企业年会、高端酒会、高峰论坛、商务论坛、商务会议、颁奖典礼、展览展示等业务。</div>
					</div>
					<div class="companyItem">
						<div class="c_title">服务领域主要覆盖<br/>互联网、红酒、农产品等行业</div>
						<img src="../../../public/img/c3.jpg" />
						<div class="c_des">以专业的水准和真诚的服务态度，要据客户的需求和自身特点量身策划，打造优质的公关活动，帮助企业实现更大的利润价值，终实现双赢。</div>
					</div>
				</div>
			</div>
			<div class="box news">
			<div class="title">
				<div class="mainTitle">NEWS CENTER</div>
				<div class="subTitleText">新闻动态</div>
			</div>
			<div class="simpleText">
				<p>以客为本，为客户办好每一场活动是我们永恒的追求</p>
			</div>
			<div class="newList leftAndRight">
				<div class="newItem divColumnCenter" v-for="(item, index) in activityList" :key="index" @click="del(item.id)">
					<img :src="item.image"/>
					<div class="right">
						<div class="n_title view-text">{{item.title}}</div>
						<div class="n_des twoRow">{{item.description}}</div>
						<div class="time">{{item.add_time}}</div>
					</div>
				</div>
			</div>
			</div>
			<div class="work" >
				<div class="title">
					<div class="mainTitle">CUSTOMER SERVICE</div>
					<div class="subTitleText">服务客户</div>
				</div>
				<div class="simpleText">
					<p>我们与客户一起为市场留下了良好的口碑和深远的品牌价值</p>
					
				</div>
				<div class="p_box box leftAndRight">
					<img  src="../../../public/img/p1.jpg"/>
					<img  src="../../../public/img/p2.jpg"/>
					<img  src="../../../public/img/p3.jpg"/>
					<img  src="../../../public/img/p4.jpg"/>
				</div>
			</div>
			<foot></foot>
			<!-- <router-view></router-view> -->
		</div>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	// import { Swiper, SwiperSlide } from "@/vue-awesome-swiper";
	// import "swiper/dist/css/swiper.css";
	export default {
		name: 'App',
		components: {
			NavigationBar,
			foot,

		},
		data() {
			return {
				interval: 3000,
				 swiperOption: {
				        loop: true,
						},
				items: [{
					image: '../../../public/img/lb1.png'
				}, {
					image: '../../../public/img/lb2.png'
				}],
				date: {},
				product: {},
				news: [],
				paneList: [{
						image: require('../../../public/img/pen1.jpg'),
						title: '舞台新闻发布会'
					},
					{
						image: require('../../../public/img/pen1.jpg'),
						title: '舞台新闻发布会'
					},
					{
						image: require('../../../public/img/pen1.jpg'),
						title: '舞台新闻发布会'
					},
				],
				activityList: [{
						image: require('../../../public/img/pen1.jpg'),
						title: '中国主题公园行业现状分析及发展趋势预测发布会',
						des: '国外用50 年时间开发了30 个主题公园，而国内用短短20 年时间就开发了2500 个主题公园，基本覆盖了我国大部...'
					},
					{
						image: require('../../../public/img/pen1.jpg'),
						title: '中国主题公园行业现状分析及发展趋势预测发布会',
						des: '国外用50 年时间开发了30 个主题公园，而国内用短短20 年时间就开发了2500 个主题公园，基本覆盖了我国大部...'
					},
					{
						image: require('../../../public/img/pen1.jpg'),
						title: '中国主题公园行业现状分析及发展趋势预测发布会',
						des: '国外用50 年时间开发了30 个主题公园，而国内用短短20 年时间就开发了2500 个主题公园，基本覆盖了我国大部...'
					}
				],
				ywList: [{
					image: require('../../../public/img/yw1.jpg')
				}, {
					image: require('../../../public/img/yw2.jpg')
				}, {
					image: require('../../../public/img/yw3.jpg')
				}, ]
			}
		},

		created() {
			this.getData()
			this.getList()
		},
		methods: {
			getData() {
				let _this = this;
				let urls =
					"api/index/index"
				_this.post(urls, res => {
					_this.items = res.data.slide;
				})
			},
			del(id){
				this.$router.push({ name: 'xiwen', query: { id: id }});
			},
			getList() {
				let _this = this;
				let urls =
					"api/article/index?category_id=2&page=1&pagesize=6"
				_this.post(urls, res => {
					_this.activityList = res.data.list;
			
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.work {
		margin-top: 30px;
	}
	.b_con {
		margin-bottom: 20px;
		text-align: left;
		.b_left,.b_right {
			width: 50%;
		}
	}
	.newList {
		flex-wrap: wrap;
		margin: 30px auto;
		.newItem {
			width: 45%;
			text-align: left;
			margin-bottom: 20px;
			img {
				width: 260px;
				height: 146px;
				margin-right: 20px;
			}
			.n_title {
				    color: #333;
				    font-size: 16px;
					
			}
			.n_title:hover  {
			    color: #f13a3a;
			}
			.n_des {
				    color: #666;
				    font-size: 14px;
				    line-height: 26px;
				    margin: 14px 0;
				    white-space: normal;
			}
			.time {
				    color: #999;
				    display: inline-block;
				    font-size: 14px;
				    line-height: 1.5;
			}
		}
	}
	.companyList {
		.companyItem {
			width: 333px;
			height: 424px;
			font-size: 14px;
			color: #222222;
			background-color: #F6F6F6;
			padding: 30px;
			text-align: left;

			.c_title {
				font-size: 24px;
				color: rgb(51, 51, 51);
				line-height: 28px;
			}

			img {
				margin: 30px auto;
				width: 100%;
				height: 200px;
			}

			.c_des {
				font-size: 14px;
				line-height: 28px;
				color: rgb(153, 153, 153);
			}
		}
	}

	.elemZoneBg {
		margin-top: 44px;

		.item {
			.elemTitle {
				color: rgb(196, 0, 0);
				font-size: 36px;
				background-color: initial;
				margin-bottom: 15px;
			}

			p {
				font-size: 14px;
				margin-bottom: 10px;
			}
		}
	}

	/deep/.el-carousel__container {
		height: 700px;
	}

	.title {
		.mainTitle {
			margin-bottom: 15px;
			font-size: 16px;
			padding-top: 10px;
			font-weight: normal;
			font-family: 微软雅黑;
			color: rgba(153, 153, 153, 1);
		}

		.subTitleText {
			font-size: 30px;
			line-height: 30px;
			font-weight: bold;
			font-family: 微软雅黑;
			color: rgba(196, 0, 0, 1);
		}
	}

	.simpleText {
		margin-top: 50px;
		font-size: 14px;
		line-height: 28px;
		color: rgb(153, 153, 153);

		p {
			margin-bottom: 22px;
		}
	}

	.service {
		margin-top: 75px;



		.tab_pane_list {
			margin-top: 10px;


			.tab_pane_item {


				image {
					width: 330px;
					height: 230px;
				}

				li {
					width: 96%;
					overflow: hidden;
					white-space: nowrap;
				}

				li div {
					margin: 10px auto;
					font-size: 14px;
					color: #666;
				}
			}
		}
	}

	.activity {
		margin-bottom: 30px;

		.a_list {
			flex-wrap: wrap;

			.a_item {
				background: #fff;
				border-radius: 6px;
				-webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
				overflow: hidden;
				min-width: 200px;
				width: 33%;
				vertical-align: top;
				height: 289px;

				img {
					height: 170px;
					width: 100%;
				}

				.a_title {
					vertical-align: middle;
					color: #333;
					font-size: 16px;
					text-align: left;
				}

				.a_dsc {
					color: #666;
					font-size: 14px;
					line-height: 26px;
					margin-top: 14px;
					white-space: normal;
					text-align: left;
				}
			}
		}
	}
</style>