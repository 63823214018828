<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="banner">
			<div class="box">
				<div class="banner_name">学习天地</div>
			</div>
		</div>
		<breadNav :tabList='tabList' @tabClick='tabClick'></breadNav>
		<div class="newshow_name box">{{dates.title}}</div>
				<div class="newshow_zx box divColumnCenter">
					<div class="newshow_timer">发布时间：{{dates.show_time}}</div>
					<!-- <div class="newshow_shu">&nbsp;&nbsp;|&nbsp;&nbsp;</div>
					<div class="newshow_follow">阅读量：<b style="font-weight:500;">301</b></div> -->
				</div>
				
				<div class="about_con box">
					<div v-html="content"></div>
				</div>
				<foot></foot>
			</div>
		</template>
		
		<script>
			import NavigationBar from '@/components/NavigationBar.vue'
			import foot from '@/components/foot.vue'
			import breadNav from '@/components/breadNav.vue'
			export default {
				components: {
					NavigationBar,
					foot,
					breadNav
		
				},
				data() {
					return {
						tabList: ['企业简介', '组织架构', '企业文化', '资质荣誉'],
						id: '',
						content: '',
						dates: {}
					}
				},
				mounted() {
					console.log(this.$route)
					this.id = this.$route.query.id;
					this.getData()
				},
				methods: {
					tabClick(id) {
						this.id =  id;
						this.getData()
					},
					getData() {
						let _this = this;
						let urls =
							"api/article/detail?id=" + _this.id
						_this.post(urls, res => {
							console.log(res.data)
							_this.dates = res.data.info;
							_this.content = res.data.info.content;
							_this.tabList=res.data.art_list
						})
					},
				}
			}
		</script>
		
		<style scoped>
			.banner {
				background: url('../../../public/img/top3.jpg');
				width: 100%;
				height: 340px;
			}
		
			.banner_name {
				padding-top: 150px;
				font-size: 44px;
				color: #474747;
				text-align: left;
			}
		
			.about_con {
				margin: 30px auto;
				text-align: left;
			}
		
			/* .about_con img {
				width: 100% ;
			} */
		
			.about_con p {
				font-size: 15px;
				line-height: 1.6;
				color: #525252;
				margin-bottom: 25px;
			}
			.newshow_name {
				font-size: 28px;
				color: #333333;
				font-weight: bold;
				line-height: 30px;
				margin-top: 50px;
				text-align: left;
			}
			.newshow_zx {
				margin-top: 20px;
				text-align: left;
			}
			.newshow_zx div {
			     font-size: 14px;
			    color: #999999;
			}
			.newshow_follow {
			    margin-right: 20px;
			}
		</style>