import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
// import Web3 from 'web3'
// Vue.prototype.Web3 = Web3
import "./css/style.css";

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)


import axios from "axios";
Vue.prototype.$ajax = axios;

import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

import api from "./api.js";
Vue.use(api); //用作插件来进行注册
// 复制粘贴功能
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Vue.prototype.formatConversion = function(te) {
// 	if (te == '') {
// 		return '';
// 	} else if (te.length == 10) {
// 		var time = new Date(te * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
// 		var y = time.getFullYear();
// 		var m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
// 		var d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
// 		var h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
// 		var mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
// 		var s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
// 		var timedate = y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
// 		return timedate;
// 	} else {
// 		var time = new Date(te);
// 		var y = time.getFullYear();
// 		var m = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
// 		var d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
// 		var h = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
// 		var mm = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
// 		var s = time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();
// 		var timedate = y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
// 		return timedate;
// 	}
// }
import moment from "moment/moment"
Vue.filter("moment", function(value, formatString) {
	formatString = formatString || "YYYY-MM-DD HH:mm";
	return moment(value).format(formatString);
});
Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
