<template>
	<div>
		<NavigationBar></NavigationBar>
		<div class="banner">
			<div class="box">
				<!-- <div class="banner_name">联系我们</div> -->
			</div>
		</div>
		<div class="title">
			<div class="mainTitle">WHO ARE WE</div>
			<div class="subTitleText">我们是谁</div>
		</div>
		<div class="simpleText">
			<p>一家策划、设计、执行一站式的专业服务公司</p>
			<p>六年的从业经验，出色的策划设计和执行能力，成功的服务上一千两百场活动，同时被客户评为“中国4A类活动策划公司”</p>
		</div>
		<div class="j_list divColumnCenter box">
			<div class="j_item">
				<p class="title">
					01、实力
				</p>
				<p>服务众多知名品牌<br/>赢得客户好评与信赖</p>
			</div>
			<div class="j_item">
				<img src="../../../public/img/lx1.jpg" />
			</div>
			<div class="j_item">
				<p class="title">
					03、服务
				</p>
				<p>聆听客户的声音<br/>追求深度服务</p>
			</div>
			<div class="j_item">
				<img src="../../../public/img/lx2.jpg" />
			</div>
			<div class="j_item">
				<img src="../../../public/img/lx3.jpg" />
			</div>
			<div class="j_item">
				<p class="title">
					02、专注
				</p>
				<p>11年大型活动经验<br/>我们更懂客户</p>
			</div>
			<div class="j_item">
				<img src="../../../public/img/lx4.jpg" />
			</div>
			<div class="j_item">
				<p class="title">
					04、品质
				</p>
				<p>注重每个细节<br/>力求尽善尽美</p>
			</div>
		</div>
		<div class="packContent leftAndRight">
			<div class="packItem">
				<div class="topTitle">软件开发</div>
				<div style="margin-top: 15px;">
					<p>了解、明确需求</p>
					<p>团队头脑风暴</p>
					<p>原型图设计</p>
					<p>企业网站</p>
					<p>方案沟通修改阶段</p>
					<p>方向性提案</p>
					<p>确定方案</p>
					<p>项目上线</p>
				</div>
			</div>
			<div class="packItem">
				<div class="topTitle">设计</div>
				<div style="margin-top: 15px;">
					<p>设计团队分析策略</p>
					<p>活动主视觉设计</p>
					<p>活动LOGO、活动吉祥物设计</p>
					<p>活动物料设计</p>
					<p>活动包装设计</p>
					<p>舞美设计</p>
					<p>活动画册设计</p>
					<p>印前工作</p>
				</div>
			</div>
			<div class="packItem">
				<div class="topTitle">策划</div>
				<div style="margin-top: 15px;">
					<p>执行动员大会</p>
					<p>活动物料制作</p>
					<p>舞美搭建</p>
					<p>现场包装</p>
					<p>礼仪、演员邀请与排演</p>
					<p>活动物料到位</p>
					<p>活动各环节执行</p>
					<p>活动完毕</p>
				</div>
			</div>
		</div>
		<div class="title">
			<div class="mainTitle">CUSTOMER SERVICE</div>
			<div class="subTitleText">聆听客户的声音  追求深度服务</div>
		</div>
		<div class="simpleText">
			<p>品牌营销、活动策划、舞美视觉设计领域从业超过6年！我们策划执行全国大中小型活动2000+</p>
			<p>我们倡导让客户感知视觉体验与活动营销的价值，而不仅仅是简单的参与</p>
		</div>
		<img class="fw" src="../../../public/img/fw.png" />
		<breadNav :tabList='tabList' @tabClick='tabClick'></breadNav>
		<div class="about_con box">
			<div class="n_content_top leftAndRight">
				<div class="n_content_t_l">
					<div class="n_content_t_l_name">联系方式</div>
					<div class="n_content_t_l_p">公司名称： 湖南三好文化传媒有限公司</div>
					<div class="n_content_t_l_p n_content_t_l_p2">地址：湖南省长沙市岳麓区金星中路湘腾城市广场1栋1单元1602室</div>
					<div class="n_content_t_l_p">联系电话：18975871319</div>
					<div class="n_content_t_l_p n_content_t_l_p2">传真：</div>
					<div class="n_content_t_l_p">网址：http://www.3hwh.cn/</div>
					<div class="n_content_t_l_p">Email：hn3hwh@163.com</div>
				</div>
				<div class="n_content_t_r">
					<div class="n_content_t_r_name">在线留言</div>
					<div class="n_content_t_r_input">
						<input name="name" v-model="name" type="text" placeholder="姓名">
					</div>
					<div class="n_content_t_r_input">
						<input name="tel" v-model="tel" type="text" placeholder="联系方式">
					</div>
					<div class="n_content_t_r_input">
						<textarea name="content" v-model="content" placeholder="内容"></textarea>
					</div>
					<div class="n_content_t_r_input2">
						<input type="submit" name="dosubmit" value="提  交" @click="tj">
					</div>
				</div>
			</div>
		</div>
		<!-- <img class="dt" src="../../../public/img/dt.png" /> -->
		<foot></foot>
	</div>
</template>

<script>
	import NavigationBar from '@/components/NavigationBar.vue'
	import foot from '@/components/foot.vue'
	import breadNav from '@/components/breadNav.vue'
	export default {
		components: {
			NavigationBar,
			foot,
			breadNav

		},
		data() {
			return {
				tabList: [],
				name: '',
				tel: '',
				content: ''
			}
		},
		methods: {
			tabClick(data) {
				console.log(data)
			},
			tj() {
				if (this.name == '') {
					alert('请输入姓名')
					return;
				}
				if (this.tel == '') {
					alert('请输入电话')
					return;
				}
				if (this.content == '') {
					alert('请输入内容')
					return;
				}
				let _this = this;
				let urls =
					"api/article/addmsg?true_name=" + _this.name+'&mobile='+_this.tel+'&message='+this.content
				_this.post(urls, res => {
					console.log(res)
					alert('提交成功')
					_this.$router.push({
						path: '/',
					})
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.fw {
		width: 1200px;
	}
	.packContent {
		margin: 20px auto auto;
		width: 1000px;
		.packItem{
			.topTitle {
				font-size: 20px;
				color: #333;
				border-bottom: 1px solid rgb(196, 0, 0);
				padding-bottom: 20px;
			}
			p {
				    font-size: 14px;
				    background-color: initial;
				    color: rgb(102, 102, 102);
					line-height: 28px;
			}
		}
	}
	.j_list {
		flex-wrap: wrap;
		margin-top: 20px;
		border-bottom: 1px solid #ccc;
		.j_item {
			width: 300px;
			height: 220px;
			p {
				    font-size: 18px;
				    line-height: 32px;
				    color: rgb(102, 102, 102);
			}
			
			.title{
				    font-size: 24px;
				    color: rgb(51, 51, 51);
					margin-top: 80px;
			}
			img {
				width: 100%;
			}
		}
	}
	.title {
		margin-top: 20px;
		.mainTitle {
			margin-bottom: 15px;
			font-size: 16px;
			padding-top: 10px;
			font-weight: normal;
			font-family: 微软雅黑;
			color: rgba(153, 153, 153, 1);
		}
	
		.subTitleText {
			font-size: 30px;
			line-height: 30px;
			font-weight: bold;
			font-family: 微软雅黑;
			color: rgba(196, 0, 0, 1);
		}
	}
	
	.simpleText {
		margin-top: 50px;
		font-size: 14px;
		line-height: 28px;
		color: rgb(153, 153, 153);
	
		p {
			margin-bottom: 10px;
		}
	}
	.banner {
		background: url('../../../public/img/bg2.jpg');
		width: 100%;
		height: 450px;
	}

	.banner_name {
		padding-top: 150px;
		font-size: 44px;
		color: #474747;
		text-align: left;
	}

	.about_con {
		margin: 30px auto;
		text-align: left;
	}

	.n_content_top {
		margin: 50px 0 18px 0;
		padding: 45px 80px;
		background-color: #fbfbfb;
		text-align: left;
		align-items: baseline;
	}

	.n_content_t_l {

		width: 530px;
	}

	.n_content_t_l_name {
		font-size: 20px;
		color: #292929;
		font-weight: bolder;
		margin-bottom: 25px;
	}

	.n_content_t_l_p {
		font-size: 15px;
		color: #5f5f5f;
		line-height: 24px;
	}

	.n_content_t_l_p2 {
		padding-bottom: 24px;
	}

	.n_content_t_r_name {
		font-size: 20px;
		color: #292929;
		font-weight: bolder;
		margin-bottom: 15px;
	}

	.n_content_t_r_input {
		margin-bottom: 19px;
	}

	.n_content_t_r_input textarea {
		padding-left: 15px;
		padding-top: 14px;
		width: 500px;
		height: 94px;
		max-height: 94px;
		border: 1px solid #EBEBEB;
	}

	.n_content_t_r_input input {
		width: 500px;
		padding-left: 15px;
		height: 43px;
		border: 1px solid #EBEBEB;
	}

	input:not([type=radio]):not([type=checkbox]),
	select {
		vertical-align: middle;
	}

	.n_content_t_r_input2 input {
		width: 150px;
		outline: none;
		height: 42px;
		font-size: 15px;
		border-radius: 20px;
		text-align: center;
		border: none;
		line-height: 42px;
		background-color: #c9151e;
		color: #ffffff;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-ms-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}

	.dt {
		width: 1200px;
		margin: 0 auto 80px;
	}
</style>